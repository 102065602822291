import React, { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Group62 from "../src/images/Group 62.svg";
import Group24 from "../src/images/image 32.png";
import { FormProvider, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";

const Donation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      selectedDate: null,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    handleClose();
  };

  const [currency, setCurrency] = useState("Select Currency");

  const handleCurrencyChange = (event: any) => {
    setCurrency(event.target.value);
  };
  const [amount, setAmount] = useState("");

  const handlePresetAmountClick = (value: any) => {
    setAmount(value);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />

        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={"100%"}
          gap={8}
          sx={{
            mt: 13,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography variant="h3" fontWeight={700} color="#FFFFFF">
            Donation
          </Typography>
          <Typography mt={isMobile ? 4 : 20} color={"#FFFFFF"}>
            Raise your hand to make our world to be a better place
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 4,
        }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          width="100%"
          maxWidth={1200}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingX: isMobile ? 2 : 0,
          }}>
          <Stack spacing={2} alignItems={isMobile ? "center" : "center"}>
            <Typography
              variant="h4"
              fontWeight={700}
              color="#164080"
              align="center">
              Let's Donate
            </Typography>
            <Stack>
              <Typography color={"#164080"} align="center" mt={4} mb={5}>
                Donating in a Gurudwara, a place of worship in Sikhism, is a
                deeply ingrained tradition rooted in the Sikh principles of
                selfless service and community support. Gurudwaras serve as not
                just spiritual centers but also as hubs for humanitarian
                activities. Sikhs believe in the concept of “Dasvandh,” wherein
                individuals contribute a tenth of their earnings for the common
                good. This donation, known as “Guru’s offering” or “Golak,”
                supports various philanthropic endeavors such as langar, a
                community kitchen that provides free meals to all visitors
                irrespective of their background. The act of giving in a
                Gurudwara extends beyond financial contributions; it also
                involves voluntary service or “Seva.” This inclusive approach to
                donation fosters a sense of equality and unity among the Sikh
                community, promoting the ideals of compassion, generosity, and
                communal well-being. The Gurudwara thus stands not only as a
                place of worship but also as a symbol of collective
                responsibility and shared humanity.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: "capitalize",
                bgcolor: "#FF7315",
              }}
              size="large"
              onClick={handleOpen}>
              Donate
            </Button>
          </Stack>
          <Stack spacing={2} maxWidth={600}>
            <img src={Group24} alt="Media2" width={isMobile ? "400" : "auto"} />
          </Stack>
        </Stack>
        <Dialog open={open} sx={{ width: "100%" }}>
          <Stack flexDirection="row" justifyContent="space-between">
            <DialogTitle variant="h6" fontWeight={700} color="#164080">
              Let’s Donate
            </DialogTitle>
            <CloseIcon
              sx={{ mt: 1, p: 2, cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>
          <Divider orientation="horizontal" flexItem />
          <DialogContent>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack spacing={4} width={"100%"}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    width="100%"
                    maxWidth={800}>
                    <Select
                    //   labelId="Currency"
                      placeholder="selected Currency"
                      value={currency}
                      onChange={handleCurrencyChange}
                      label="Currency"
                      sx={{
                        border: "1px solid #5FAFFF",
                        borderRadius: 7,
                        width: { xs: '100%', sm: 250 },
                      }}>
                        <MenuItem value="Select Currency">
                        Select Currency
                      </MenuItem>
                      <MenuItem value="Canadian Dollar (CAD)">
                        Canadian Dollar (CAD)
                      </MenuItem>
                      <MenuItem value="US Dollar (USD)">
                        US Dollar (USD)
                      </MenuItem>
                      <MenuItem value="British Pound (GBP)">
                        British Pound (GBP)
                      </MenuItem>
                      <MenuItem value="Indian Rupee (INR)">
                        Indian Rupee (INR)
                      </MenuItem>
                      <MenuItem value="Australian Dollar (AUD)">
                        Australian Dollar (AUD)
                      </MenuItem>
                    </Select>
                    <TextField
                      placeholder="Enter Custom Amount"
                      variant="outlined"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      InputProps={{
                        sx: {
                          border: "1px solid #5FAFFF",
                          borderRadius: 7,
                          width: { xs: '100%', sm: 340 },
                        },
                      }}
                      fullWidth
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      onClick={() => handlePresetAmountClick("$51.00")} sx={{borderRadius:7}} >
                      $51.00
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handlePresetAmountClick("$101.00")}sx={{borderRadius:7}}>
                      $101.00
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handlePresetAmountClick("$151.00")} sx={{borderRadius:7}}>
                      $151.00
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handlePresetAmountClick("$251.00")} sx={{borderRadius:7}}>
                      $251.00
                    </Button>
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    width="100%"
                    maxWidth={800}>
                    <TextField
                      placeholder="First Name"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                      }}
                    />
                    <TextField
                      placeholder="Last Name"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    width="100%"
                    maxWidth={800}>
                    <TextField
                      placeholder="Email Address"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                      }}
                    />
                  </Stack>
                  <TextField
                    placeholder="Cause of Donation"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                    }}
                  />
                </Stack>
                <Stack mt={2}>
                  {" "}
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      bgcolor: "#FF7315",
                      borderRadius: 5,
                      textTransform: "capitalize",
                    }}>
                    Donate Now
                  </Button>
                </Stack>
                <DialogActions></DialogActions>
              </form>
            </FormProvider>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default Donation;
