import React from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import Media2 from "../src/images/Media (2).png";
import Group56 from "../src/images/Group 56.png";
import Group62 from "../src/images/Group 62.svg";

const Diwan = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            // zIndex: -1,
          }}
        />

        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth="100%"
          gap={8}
          sx={{
            position: "relative", 
            top: "70%", 
            left: "50%", 
            transform: "translate(-50%, -50%)",
       
          }}>
          <Typography variant="h3" fontWeight={700} color="#FFFFFF">
            Diwan
          </Typography>
          <Typography color="#FFFFFF">
            Come, sit in the Diwan, and embrace the peace and wisdom of the
            Guru.
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 4,
        }}>
        {/* Main Content Section */}
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          width="100%"
          maxWidth={1200}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingX: isMobile ? 2 : 0,
          }}>
          {/* Left Content */}
          <Stack spacing={2} alignItems={isMobile ? "center" : "flex-start"}>
            <Typography
              variant="h4"
              fontFamily="Merriweather"
              fontWeight={700}
              color="#164080">
              Program Schedule
            </Typography>
            <Stack flexDirection={"row"} gap={2}>
              {" "}
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: 6,
                  height: 6,
                  backgroundColor: "#164080",
                  borderRadius: "50%",
                  mt: 1,
                }}
              />
              <Stack>
                <Typography
                  fontFamily="Merriweather"
                  variant="body1"
                  fontWeight={700}
                  color={"#164080"}>
                  11:30 AM
                </Typography>{" "}
              </Stack>
              <Stack>
                <Typography fontFamily="Merriweather" color={"#164080"}>
                  Sangat and Kids Kirtan
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              {" "}
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: 6,
                  height: 6,
                  backgroundColor: "#164080",
                  borderRadius: "50%",
                  mt: 1,
                }}
              />
              <Stack>
                <Typography
                  fontFamily="Merriweather"
                  variant="body1"
                  fontWeight={700}
                  color={"#164080"}>
                  12:00 AM
                </Typography>{" "}
              </Stack>
              <Stack>
                <Typography fontFamily="Merriweather" color={"#164080"}>
                  Kirtan by Bhai Sahib
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              {" "}
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: 6,
                  height: 6,
                  backgroundColor: "#164080",
                  borderRadius: "50%",
                  mt: 1,
                }}
              />
              <Stack>
                <Typography
                  fontFamily="Merriweather"
                  variant="body1"
                  fontWeight={700}
                  color={"#164080"}>
                  1:15 PM
                </Typography>{" "}
              </Stack>
              <Stack>
                <Typography fontFamily="Merriweather" color={"#164080"}>
                  Ardas and Hukamnama
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              {" "}
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: 6,
                  height: 6,
                  backgroundColor: "#164080",
                  borderRadius: "50%",
                  mt: 1,
                }}
              />
              <Stack>
                <Typography
                  fontFamily="Merriweather"
                  variant="body1"
                  fontWeight={700}
                  color={"#164080"}>
                  1:30 PM
                </Typography>{" "}
              </Stack>
              <Stack>
                <Typography fontFamily="Merriweather" color={"#164080"}>
                  Parshad and Langar
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography fontFamily="Merriweather" color="#164080">
                To Sponsor the Diwan{" "}
                <Link
                  href="#"
                  sx={{
                    color: "#FF7315",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}>
                  Click Here
                </Link>{" "}
                (Sponsorship Money)
              </Typography>
              <Typography fontFamily="Merriweather" color={"#164080"}>
                goes towards gurdwara operating expense)
              </Typography>
            </Stack>
            <Box
              border={1}
              borderColor="#164080"
              borderRadius="4px"
              width={isMobile ? "400" : "auto"}
              p={2}>
              <Typography fontFamily="Merriweather" color="#164080">
                Volunteers are needed to help set up and clean up after the
                diwan. To volunteer in the setup, please arrive at the gurdwara
                sahib at 10:15 AM. To help in the cleanup, please plan to stay
                after the langar.
              </Typography>
            </Box>
          </Stack>

          {/* Right Content */}
          <Stack spacing={2} maxWidth={600}>
            <img src={Media2} alt="Media2" width={isMobile ? "400" : "auto"} />
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} gap={2} mt={8}>
          <img src={Group56} alt="Group56" width={isMobile ? "400" : "auto"} />
        </Stack>
      </Grid>
    </>
  );
};

export default Diwan;
