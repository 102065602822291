import React, { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Group62 from "../src/images/Group 62.svg";
import Group24 from "../src/images/image 30.png";
import { FormProvider, useForm } from "react-hook-form";
import Image8 from "../src/images/image 8.png";
import Image9 from "../src/images/image 9.png";
import Image13 from "../src/images/image 13.png";
import Image10 from "../src/images/image 10.png";
import Image19 from "../src/images/image 19.png";
import CloseIcon from "@mui/icons-material/Close";

const BookingEvent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      selectedDate: null,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    handleClose();
  };

  const [selectedTime, setSelectedTime] = useState("");

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />

        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={"100%"}
          gap={8}
          sx={{
            mt: 13,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography variant="h3" fontWeight={700} color="#FFFFFF">
            Events Booking
          </Typography>
          <Typography mt={isMobile ? 4 : 20} color={"#FFFFFF"}>
            Embracing Humanity: The Sacred Tradition of Langar Seva in Gurdwara.
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 1,
        }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          width="100%"
          maxWidth={1200}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingX: isMobile ? 2 : 0,
          }}>
          <Stack direction={isMobile ? "column" : "row"} gap={2} width="100%">
            <Stack gap={2} width="100%">
              <div
                style={{ position: "relative", width: "100%", height: "auto" }}>
                <img
                  src={Image8}
                  alt="Image8"
                  style={{ width: "100%", height: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }}>
                  <Typography variant="h6" fontWeight={700} color="white">
                    Wedding
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 5,
                      textTransform: "capitalize",
                      bgcolor: "#FF7315",
                    }}
                    size="large"
                    onClick={handleOpen}>
                    Book Now
                  </Button>
                </div>
              </div>

              <div
                style={{ position: "relative", width: "100%", height: "auto" }}>
                <img
                  src={Image9}
                  alt="Image9"
                  style={{ width: "100%", height: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }}>
                  <Typography variant="h6" fontWeight={700} color="white">
                    Langar
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 5,
                      textTransform: "capitalize",
                      bgcolor: "#FF7315",
                    }}
                    size="large"
                    onClick={handleOpen}>
                    Book Now
                  </Button>
                </div>
              </div>
            </Stack>

            <Stack direction="column" gap={2} width="100%">
              <Stack
                direction={isMobile ? "column" : "row"}
                gap={2}
                width="100%">
                <div
                  style={{
                    position: "relative",
                    width: isMobile ? "100%" : 300,
                  }}>
                  <img src={Image10} alt="Image10" style={{ width: "100%" }} />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}>
                    <Typography variant="h6" fontWeight={700} color="white">
                      Akhand Paath
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 5,
                        textTransform: "capitalize",
                        bgcolor: "#FF7315",
                      }}
                      size="large"
                      onClick={handleOpen}>
                      Book Now
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    position: "relative",
                    width: isMobile ? "100%" : 260,
                  }}>
                  <img src={Image13} alt="Image13" style={{ width: "100%" }} />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}>
                    <Typography variant="h6" fontWeight={700} color="white">
                      Birthday
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 5,
                        textTransform: "capitalize",
                        bgcolor: "#FF7315",
                      }}
                      size="large"
                      onClick={handleOpen}>
                      Book Now
                    </Button>
                  </div>
                </div>
              </Stack>

              <div style={{ position: "relative", width: "100%", height: 440 }}>
                <img
                  src={Image19}
                  alt="Image19"
                  style={{ width: "100%", height: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }}>
                  <Typography variant="h6" fontWeight={700} color="white">
                    Sehaj Paath
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 5,
                      textTransform: "capitalize",
                      bgcolor: "#FF7315",
                    }}
                    size="large"
                    onClick={handleOpen}>
                    Book Now
                  </Button>
                  <Dialog
                    open={open}
                    fullWidth
                    maxWidth="md"
                    sx={{
                      width: "100%",
                      maxWidth: { xs: "100%", sm: 800, md: 1400 },
                    }}>
                    <Stack flexDirection="row" justifyContent="space-between">
                      <DialogTitle
                        variant="h6"
                        fontWeight={700}
                        color="#164080">
                        Event Booking
                      </DialogTitle>
                      <CloseIcon
                        sx={{ mt: 1, p: 2, cursor: "pointer" }}
                        onClick={handleClose}
                      />
                    </Stack>
                    <Divider orientation="horizontal" flexItem />
                    <DialogContent>
                      <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                          <Stack spacing={4} width={"100%"}>
                            {/* First Row of TextFields */}
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              spacing={2}
                              width="100%"
                              maxWidth={800}>
                              <TextField
                                placeholder="Number of People"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  sx: {
                                    border: "1px solid #5FAFFF",
                                    borderRadius: 7,
                                  },
                                }}
                              />
                              <TextField
                                placeholder="Full Name"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  sx: {
                                    border: "1px solid #5FAFFF",
                                    borderRadius: 7,
                                  },
                                }}
                              />
                            </Stack>

                            {/* Second Row of TextFields */}
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              spacing={2}
                              width="100%"
                              maxWidth={800}>
                              <TextField
                                placeholder="Email"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  sx: {
                                    border: "1px solid #5FAFFF",
                                    borderRadius: 7,
                                  },
                                }}
                              />
                              <TextField
                                placeholder="Phone No."
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  sx: {
                                    border: "1px solid #5FAFFF",
                                    borderRadius: 7,
                                  },
                                }}
                              />
                            </Stack>

                            {/* Date Picker */}
                            <TextField
                              placeholder="Select Date"
                              type="date"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                sx: {
                                  border: "1px solid #5FAFFF",
                                  borderRadius: 7,
                                  maxWidth: { xs: "100%", sm: 100, md: 390 },
                                },
                              }}
                            />
                          </Stack>

                          <Typography mt={5} color={"#164080"} mb={2}>
                            Available time Slots
                          </Typography>

                          {/* Time Slot Buttons */}
                          <Stack direction="row" spacing={2}>
                            {[
                              "12:30 AM - 1:30 PM",
                              "1:30 PM - 2:30 PM",
                              "2:30 PM - 3:30 PM",
                              "3:30 PM - 4:30 PM",
                            ].map((time) => (
                              <Button
                                key={time}
                                size="small"
                                variant={
                                  selectedTime === time
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => handleTimeChange(time)}
                                sx={{
                                  border: "1px solid #5FAFFF",
                                  borderRadius: 7,
                                  padding: "8px 16px",
                                  color:
                                    selectedTime === time ? "white" : "#5FAFFF",
                                  bgcolor:
                                    selectedTime === time
                                      ? "#5FAFFF"
                                      : "transparent",
                                }}>
                                {time}
                              </Button>
                            ))}
                          </Stack>

                          {/* Submit Button */}
                          <DialogActions>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                bgcolor: "#FF7315",
                                borderRadius: 5,
                                textTransform: "capitalize",
                              }}>
                              Confirm
                            </Button>
                          </DialogActions>
                        </form>
                      </FormProvider>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default BookingEvent;
