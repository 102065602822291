import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import Logo from '../src/images/Vector.svg'
import { Link } from "react-router-dom";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const handleItemClick = (text: any) => {
    setSelectedItem(text);
    setDrawerOpen(false);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerContent = (
    <Box
      sx={{ width: 220 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List>
        {[
          "Home",
          "Diwan",
          "Langer Sewa",
          "Live Events",
          "Event Booking",
          "Kids Classes",
          "Donation",
          "Contact Us",
        ].map((text) => (
          <ListItem
            // button
            key={text}
            component={Link}
            to={`/${text
              .toLowerCase()
              .replace(/ and /g, "-")
              .replace(/ /g, "-")}`}
            onClick={() => handleItemClick(text)}
            sx={{
              color: selectedItem === text ? "white" : "#303981",
              backgroundColor:
                selectedItem === text ? "#303981" : "transparent",
              "&:hover": {
                backgroundColor: "rgba(48, 57, 129, 0.1)",
              },
              padding: "10px 16px",
            }}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <ListItem>
          <Button sx={{  textTransform: "capitalize" }}>
            Login
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="outlined"
            sx={{ textTransform: "capitalize" }}>
            Donate
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: `#164080`,
          paddingTop: "10px",
          boxShadow: "none",
          color: "#303981",
        }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          }}>
          {isMobile && (
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ color: "white", ml: 1 }}>
              <ListIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              ml: 13,
            }}>
            {!isMobile && (
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
                indicatorColor="primary"
                sx={{
                  "& .MuiTab-root": {
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    minWidth: "auto",
                  },
                  "& .Mui-selected": {
                    color: "#FF7315",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#303981",
                  },
                  // flexGrow: 1,
                }}>
                <Tab component={Link} to="/home" label="Home" />
                <Tab component={Link} to="/diwan" label="Diwan" />
                <Tab component={Link} to="/langer-sewa" label="Langer Seva" />
                <Tab component={Link} to="/live-events" label="Live Events" />
                
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    height: "40px",
                    marginLeft: isMobile ? 0 : "auto",
                    marginRight: isMobile ? 0 : "auto",
                  }}
                />
                <Tab
                  component={Link}
                  to="/event-booking"
                  label=" Event Booking "
                />
                <Tab component={Link} to="/kids-classes" label="Kids Classes" />
                <Tab component={Link} to="/donation" label="Donation" />
                <Tab component={Link} to="/contact-us" label="Contact Us" />
              </Tabs>
            )}
          </Box>
          {!isMobile && (
            <Box sx={{ display: "flex", gap: 1,mr:6 }}>
              <Button sx={{ color: "white", textTransform: "capitalize" }}>
                Login
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "white",
                  textTransform: "capitalize",
                  borderRadius: 5,
                  "&:hover": {
                    borderColor: "white",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}>
                Donate
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
}

export default Header;
