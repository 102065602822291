import React, { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Group62 from "../src/images/Group 62.svg";
import Group24 from "../src/images/image 30.png";
import { FormProvider, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";

const steps = [
  " Service Selection",
  "Date & Time",
  "Your Information",
  "Family Details",
  "Payments",
];

const KidsClasses = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState("Select Currency");
  const [amount, setAmount] = useState("");

  const handlePresetAmountClick = (value: any) => {
    setAmount(value);
  };

  const handleCurrencyChange = (event: any) => {
    setCurrency(event.target.value);
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      selectedDate: null,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    handleNext();
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />

        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={"100%"}
          gap={8}
          sx={{
            mt: 13,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography variant="h3" fontWeight={700} color="#FFFFFF">
            Kids Classes
          </Typography>
          <Typography mt={isMobile ? 4 : 20} color={"#FFFFFF"}>
            Singh Sabha Gurdwara of Austin is open daily with weekly Diwan on
            Sundays.
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 4,
        }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          width="100%"
          maxWidth={1200}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingX: isMobile ? 2 : 0,
          }}>
          <Stack spacing={2} alignItems={isMobile ? "center" : "center"}>
            <Typography
              variant="h4"
              fontWeight={700}
              color="#164080"
              align="center">
              Kids Classes
            </Typography>
            <Stack>
              <Typography color={"#164080"} align="center" mt={4} mb={5}>
                Punjabi classes are held on the first Saturday of every month,
                before the Diwan at the Presbyterian Church, according to the
                following schedule: Gurmat Class: 10:30AM-11:15 AM Gurmukhi
                Class: 11:15 AM-12:00 PM Currently we have three levels for kids
                based on their age and their familiarity with Gurmukhi and
                Gurmat.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: "capitalize",
                bgcolor: "#FF7315",
              }}
              size="large"
              onClick={handleOpen}>
              Book Now
            </Button>
          </Stack>
          <Stack spacing={2} maxWidth={600}>
            <img src={Group24} alt="Media2" width={isMobile ? "400" : "auto"} />
          </Stack>
        </Stack>
        <Dialog open={open} fullWidth maxWidth="md">
          <Stack flexDirection="row" justifyContent="space-between">
            <DialogTitle variant="h6" fontWeight={700} color="#164080">
              To Join Classes
            </DialogTitle>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ mt: 1, p: 2, cursor: "pointer" }} />
            </IconButton>
          </Stack>

          <Divider orientation="horizontal" flexItem />
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} sx={{ height: "10vh", mt: 2 }}>
                <StepLabel
                  StepIconProps={{
                    sx: {
                      fontSize: "2rem", 
                      width: "38px", 
                      height: "30px", 
                    },
                  }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <DialogContent>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack spacing={4} width={"100%"}>
                  {activeStep === 0 && (
                    <Stack
                      direction={{ xs: "column", sm: "column" }}
                      spacing={2}>
                      <TextField
                        placeholder="Number of Kids"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                        }}
                      />
                      <Select
                        // labelId="Currency"
                        placeholder="selected Currency"
                        value={currency}
                        onChange={handleCurrencyChange}
                        label="Currency"
                        sx={{
                          border: "1px solid #5FAFFF",
                          borderRadius: 7,
                          // width: { xs: '100%', sm: 250 },
                        }}>
                        <MenuItem value="Select Currency">
                          Select Currency
                        </MenuItem>
                        <MenuItem value="Canadian Dollar (CAD)">
                          Canadian Dollar (CAD)
                        </MenuItem>
                        <MenuItem value="US Dollar (USD)">
                          US Dollar (USD)
                        </MenuItem>
                        <MenuItem value="British Pound (GBP)">
                          British Pound (GBP)
                        </MenuItem>
                        <MenuItem value="Indian Rupee (INR)">
                          Indian Rupee (INR)
                        </MenuItem>
                        <MenuItem value="Australian Dollar (AUD)">
                          Australian Dollar (AUD)
                        </MenuItem>
                      </Select>
                    </Stack>
                  )}

                  {activeStep === 1 && (
                    <Stack
                      direction={{ xs: "column", sm: "column" }}
                      spacing={2}>
                      <TextField
                        placeholder="Select Date"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          sx: {
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            // maxWidth: { xs: "100%", sm: 100, md: 390 },
                          },
                        }}
                      />
                      <Typography variant="body1">
                        Available time Slots
                      </Typography>
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handlePresetAmountClick("12:30 AM - 1:30 PM")
                          }
                          sx={{ borderRadius: 7 }}>
                          12:30 AM - 1:30 PM
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handlePresetAmountClick("1:30 PM - 2:30 PM")
                          }
                          sx={{ borderRadius: 7 }}>
                          1:30 PM - 2:30 PM
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handlePresetAmountClick("2:30 PM - 3:30 PM")
                          }
                          sx={{ borderRadius: 7 }}>
                          2:30 PM - 3:30 PM
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handlePresetAmountClick(" 3:30 PM - 4:30 PM")
                          }
                          sx={{ borderRadius: 7 }}>
                          3:30 PM - 4:30 PM
                        </Button>
                      </Stack>
                    </Stack>
                  )}

                  {activeStep === 2 && (
                    <Grid>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}>
                        <TextField
                          placeholder="First Name"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                        <TextField
                          placeholder="Last Name"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}
                        mt={2}>
                        <TextField
                          placeholder="Email"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                        <TextField
                          placeholder="Phone No."
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}
                        mt={2}>
                        <TextField
                          placeholder="Student Age"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          placeholder="Currency"
                          fullWidth
                          sx={{
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            // width: { xs: '100%', sm: 250 },
                          }}>
                          <MenuItem value="Select Currency">
                            Has your child taken Gurmukhi lessons before?
                          </MenuItem>
                        </Select>
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}
                        mt={2}>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          placeholder="Currency"
                          fullWidth
                          sx={{
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            // width: { xs: '100%', sm: 250 },
                          }}>
                          <MenuItem value="Select Currency">
                            All Student - Sweatshirt/ T-Shirt Size
                          </MenuItem>
                        </Select>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          placeholder="Currency"
                          fullWidth
                          sx={{
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            // width: { xs: '100%', sm: 250 },
                          }}>
                          <MenuItem value="Select Currency">
                            Anything else we need to know about the students
                          </MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  )}

                  {activeStep === 3 && (
                    <Grid>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}
                        mt={2}>
                        <TextField
                          placeholder="Parents Name"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                        <TextField
                          placeholder="Parents Email Id"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}
                        mt={2}>
                        <TextField
                          placeholder="Parents Contact No."
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            sx: {
                              border: "1px solid #5FAFFF",
                              borderRadius: 7,
                            },
                          }}
                        />
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          placeholder="Currency"
                          fullWidth
                          sx={{
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            // width: { xs: '100%', sm: 250 },
                          }}>
                          <MenuItem value="Select Currency">
                            Will your be interested for learning Kirtan & Tabla?
                          </MenuItem>
                        </Select>
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        width="100%"
                        maxWidth={800}
                        mt={2}>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          placeholder="Currency"
                          fullWidth
                          sx={{
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            width: { xs: "100%", sm: 390 },
                          }}>
                          <MenuItem value="Select Currency">
                            What are your goals for your student in learning
                            punjabi?
                          </MenuItem>
                        </Select>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          placeholder="Currency"
                          fullWidth
                          sx={{
                            border: "1px solid #5FAFFF",
                            borderRadius: 7,
                            // width: { xs: '100%', sm: 250 },
                          }}>
                          <MenuItem value="Select Currency">
                            Would you be Interested in Volunteering?
                          </MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  )}

                  {activeStep === 4 && (
                    <Typography color="#164080">
                      Review your information before submitting.
                    </Typography>
                  )}

                  <DialogActions>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ textTransform: "capitalize" }}>
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        bgcolor: "#FF7315",
                        borderRadius: 5,
                        textTransform: "capitalize",
                      }}>
                      {activeStep === steps.length - 1 ? "Submit" : "Continue"}
                    </Button>
                  </DialogActions>
                </Stack>
              </form>
            </FormProvider>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default KidsClasses;
