import React from "react";
import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Diwan from "./diwan";
import LangerSeva from "./langerseva";
import LiveEvent from "./images/liveevent";
import KidsClasses from "./KidsClasess";
import BookingEvent from "./booking";
import Donation from "./donation";
import ContactUs from "./Contactus";
import Footer from "./fotter";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";


function App() {
  return (
    <Router>
       <ThemeProvider theme={theme}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/diwan" element={<Diwan/>} />
        <Route path="/langer-sewa" element={<LangerSeva/>} />
        <Route path="/live-events" element={<LiveEvent/>} />
        <Route path="/kids-classes" element={<KidsClasses/>} />
        <Route path="/event-booking" element={<BookingEvent/>} />
        <Route path="/donation" element={<Donation/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
      </Routes>
      <Footer/>
      </ThemeProvider>
    </Router>
  );
}

export default App;
