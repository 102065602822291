import React, { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Media2 from "../src/images/Media (2).png";
import Group56 from "../src/images/Group 56.png";
import Group62 from "../src/images/Group 62.svg";
import Group24 from "../src/images/Group 24.png";
import Image24 from "../src/images/Group 24.png";
import Image25 from "../src/images/image 25.png";
import Image5 from "../src/images/image 5.png";
import Image7 from "../src/images/image 7.png";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DateRange } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const LangerSeva = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      selectedDate: null,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />

        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={"100%"}
          gap={8}
          sx={{
            mt: 13,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography variant="h3" fontWeight={700} color="#FFFFFF">
            Langar Seva
          </Typography>
          <Typography mt={isMobile ? 4 : 20} color={"#FFFFFF"}>
            Join us in Langar Seva, where selfless service nourishes the body
            and soul.
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 4,
        }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          width="100%"
          maxWidth={1200}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingX: isMobile ? 2 : 0,
          }}>
          <Stack spacing={2} alignItems={isMobile ? "center" : "center"}>
            <Typography
              variant="h4"
              fontWeight={700}
              color="#164080"
              align="center">
              Langar Seva
            </Typography>
            <Stack>
              <Typography color={"#164080"} align="center" mt={4} mb={5}>
                The SSGA appreciates your co-operation and understanding in
                preparing a simple Langar as per Guru-Maryada. The management
                would appreciate it if all Langar items be prepared within
                Gurudwara’ s Kitchen. Please contact the management of Singh
                Sabha or Bhai Sahib if you have plans to bring ready-to-go
                langar items that are not prepared within Gurdwara’ s kitchen to
                ensure Maryada.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: "capitalize",
                bgcolor: "#FF7315",
              }}
              size="large"
              onClick={handleOpen}>
              Book Now
            </Button>
          </Stack>

          <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", sm: 800, md: 1400 },
            }}>
            <Stack flexDirection="row" justifyContent="space-between">
              <DialogTitle variant="h6" fontWeight={700} color="#164080">
                Langar Signup
              </DialogTitle>
              <CloseIcon
                sx={{ mt: 1, p: 2, cursor: "pointer" }}
                onClick={handleClose}
              />
            </Stack>
            <Divider orientation="horizontal" flexItem />
            {/* Form inside the Dialog */}
            <DialogContent>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack spacing={2}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      width="100%"
                      maxWidth={800}>
                      <TextField
                        placeholder="First Name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                        }}
                      />
                      <TextField
                        placeholder="Phone No."
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                        }}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      width="100%"
                      maxWidth={800}>
                      <TextField
                        placeholder="Email"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                        }}
                      />
                      <TextField
                        placeholder="Select Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                        }}
                      />
                    </Stack>
                    <TextField
                      placeholder="Message"
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      sx={{
                        width: "100%",
                        maxWidth: 800,
                        borderColor: "#5FAFFF",
                        borderRadius: 2,
                      }}
                      InputProps={{
                        sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                      }}
                    />
                  </Stack>
                  <Stack mt={3} pl={{ xs: 2, sm: 5, md: 30 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        bgcolor: "#FF7315",
                        borderRadius: 5,
                        textTransform: "capitalize",
                        width: { xs: "100%", sm: 300 },
                      }}>
                      Send
                    </Button>
                  </Stack>

                  {/* Submit Button */}
                  <DialogActions></DialogActions>
                </form>
              </FormProvider>
            </DialogContent>
          </Dialog>

          {/* Right Content */}
          <Stack spacing={2} maxWidth={600}>
            <img src={Group24} alt="Media2" width={isMobile ? "400" : "auto"} />
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{
            mt: 10,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Card
            sx={{
              width: 300,
              textAlign: "center",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "70vh",
            }}>
            <CardMedia
              component="img"
              height="140"
              image={Image24}
              alt="Image 1"
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                color="#164080"
                fontWeight={700}
                align="left">
                Saturday
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                . While Gurudwara Sahib will provide most of the Langar items
                (raasan) required for Langar preparation, Any items that the
                sevak family wishes to contribute to Langar should be delivered
                by 5 pm on Saturday.
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                . All the chopping of vegetables, onions, ginger, etc. will
                start around 6 pm on Saturday. Cooking is performed on Sunday
                mornings.
              </Typography>
            </CardContent>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                mt: 2,
                textTransform: "capitalize",
                bgcolor: "#FF7315",
              }}
              size="large">
              Read more
            </Button>
          </Card>

          {/* Card 2 */}
          <Card
            sx={{
              width: 300,
              textAlign: "center",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "70vh",
            }}>
            <CardMedia
              component="img"
              height="140"
              image={Image25}
              alt="Image 2"
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                color="#164080"
                fontWeight={700}
                align="left">
                Sunday
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                . The cooking of Langar will start early in the Morning around
                7:30 am.
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                . While Langar Sevadars will take the lead in all langar
                preparation related activities, the
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                . Sevak family and their friends are all welcome and encouraged
                to help with any or all Aspects of Langar Seva.
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                . A simple tea with Pakoras or another similar snack will be
                available to the Sanga.
              </Typography>
            </CardContent>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                mt: 2,
                textTransform: "capitalize",
                bgcolor: "#FF7315",
              }}
              size="large">
              Read more
            </Button>
          </Card>

          {/* Card 3 */}
          <Card
            sx={{
              width: 300,
              textAlign: "center",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "70vh",
            }}>
            <CardMedia
              component="img"
              height="140"
              image={Image5}
              alt="Image 3"
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                color="#164080"
                align="left"
                fontWeight={700}>
                Embracing Diversity & Inclusivity
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                One of the most remarkable aspects of Langar Seva is its
                inclusivity. Whether rich or poor, old or young, everyone sits
                together on the floor in Pangat (rows) and partakes in the meal
                with utmost humility and equality. This practice breaks down
                societal barriers, fostering a sense of belonging and unity
                among all who share the meal.
              </Typography>
            </CardContent>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                mt: 2,
                textTransform: "capitalize",
                bgcolor: "#FF7315",
              }}
              size="large">
              Read more
            </Button>
          </Card>

          {/* Card 4 */}
          <Card
            sx={{
              width: 300,
              textAlign: "center",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "70vh",
            }}>
            <CardMedia
              component="img"
              height="140"
              image={Image7}
              alt="Image 4"
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                color="#164080"
                align="left"
                fontWeight={700}>
                The Spiritual and Social Impact
              </Typography>
              <Typography variant="body2" color="#164080" align="left">
                Beyond nourishing the body, Langar nourishes the spirit. It
                serves as a potent reminder of the Sikh principle of ‘ Sarbat da
                Bhala’ – the well-being of all, and stands as a beacon of
                humanitarianism. The Langar Seva extends its impact beyond the
                Gurudwara, often reaching out to those in need during natural
                disasters, emergencies, or feeding the homeless, exemplifying
                the ....
              </Typography>
            </CardContent>
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                mt: 2,
                bgcolor: "#FF7315",
                textTransform: "capitalize",
              }}
              size="large">
              Read more
            </Button>
          </Card>
        </Stack>
      </Grid>
    </>
  );
};

export default LangerSeva;
