import React from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { Link } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";
import Footerimage from "../src/images/Group 63.png";
import Logo from './images/Vector.svg';


function Footer() {

  return (
    <>
        <Box
        sx={{
          position: "relative",
          maxWidth: "100%",
        //   overflow: "hidden",
          p:8
        }}>
        <img
          src={Footerimage}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
        <Container maxWidth="lg">
          <Grid container spacing={3} justifyContent="space-around">
            <Grid item xs={6} md={1} textAlign="center">
            <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    height: "40px",
                  }}
                />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography
                color="white"
                sx={{ fontWeight: 700, mb: 2, fontSize: "19px" }}>
                Links
              </Typography>
              <Typography
                color="white"
                sx={{ fontWeight: 100, lineHeight: "35px" }}>
                <Link to="/home" style={{ textDecoration: "none", color: "white" }}>
                  Home
                </Link>
                <br />
                <Link
                  to="/contact-us"
                  style={{ textDecoration: "none", color: "white" }}>
                  About us
                </Link>
                <br />
                <Link
                  to="/kids-classes"
                  style={{ textDecoration: "none", color: "white" }}>
                  Kids Classes
                </Link>
                <br />
                <Link
                  to="/event-booking"
                  style={{ textDecoration: "none", color: "white" }}>
                  Event Booking
                </Link>
                <br />
                <Link
                  to="/diwan"
                  style={{ textDecoration: "none", color: "white" }}>
                  Diwan & Langer
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  Construction
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography
                color="white"
                sx={{ fontWeight: 700, mb: 2, fontSize: "19px" }}>
                Gurbani Links
              </Typography>
              <Typography
                color="white"
                sx={{ lineHeight: "35px", fontWeight: 100 }}>
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  Gurbani Kirtan
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  Sikhi To The Max
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  Search Gurbani
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  Sri Granth
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography
                color="white"
                sx={{ fontWeight: 700, mb: 2, fontSize: "19px" }}>
                Sikhism Links
              </Typography>
              <Typography
                color="white"
                sx={{ lineHeight: "35px", fontWeight: 100 }}>
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  Sikhs Home Page
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  BBC World Service
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  The Sikh Coalition
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  Sikh Media Watch
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography
                color="white"
                sx={{ fontWeight: 700, mb: 2, fontSize: "19px" }}>
                Contacts
              </Typography>
              <Typography
                color="white"
                sx={{ lineHeight: "35px", fontWeight: 100 }}>
                Sikhs 6404 J M Holloway Lane, AUSTIN TEXAS 787246
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  (512) 222 - SSGA
                </Link>
                <br />
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "white" }}>
                  Sikh Media Watch
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <AppBar
        position="relative"
        component="footer"
        sx={{
          backgroundColor: "#092050",
          py:5,
        }}>
        <Container maxWidth="lg">
          <Grid container spacing={3} textAlign={{ xs: "center", md: "left" }}>
            <Grid item xs={6} md={6}>
              <Typography color="white" fontSize={12}>
                © 2024 All Rights Reserved
              </Typography>

              <Grid container sx={{ fontSize: "16px", mt: 2 }}>
                <Grid item xs={12} md={3}>
                  <Typography color="white" fontSize={14}>
                    Privacy Policy
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="white" fontSize={14}>
                    Terms of Use
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="white" fontSize={14}>
                    Sales and Refunds
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography color="white" fontSize={14}>
                    Legal
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography color="white" fontSize={14}>
                    Site Map
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </>
  );
}

export default Footer;
