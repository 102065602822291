import React, { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Group62 from "../images/Group 62.svg";

import { Controller, FormProvider, useForm } from "react-hook-form";
import image12 from "../images/image 12.png";
import image15 from "../images/image 15.png";

const LiveEvent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isAttending, setIsAttending] = useState(false);
  const [isAttending1, setIsAttending1] = useState(false);
  const [isAttending2, setIsAttending2] = useState(false);

  const handleClick = () => {
    setIsAttending(true);
  };
  const handleClick1 = () => {
    setIsAttending1(true);
  };
  const handleClick2 = () => {
    setIsAttending2(true);
  };

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      selectedDate: null,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />

        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={"100%"}
          gap={8}
          sx={{
            mt: 13,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography variant="h3" fontWeight={700} color="#FFFFFF">
            Live Events
          </Typography>
          <Typography mt={isMobile ? 4 : 20} color={"#FFFFFF"}>
            Embracing Humanity: The Sacred Tradition of Langar Seva in Gurdwara.
          </Typography>
        </Stack>
      </Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          bgcolor: "#EBF5FF",
          padding: 4,
        }}>
        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{
            mt: 3,
            mb: 5,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography color="#FF7315" mt={isMobile ? 8 : 20} variant="h6">
            Events
          </Typography>
          <Typography
            variant="h3"
            align="center"
            fontWeight={700}
            color="#164080">
            Upcoming Events
          </Typography>
        </Stack>
        <Grid item xs={12} sm={10} md={8} lg={10}>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              p: 2,
            }}>
            <Box
              sx={{
                mr: { sm: 2 },
                mb: { xs: 2, sm: 0 },
              }}>
              <img
                src={image12}
                alt="Annual Baisakhi Celebration"
                style={{ maxWidth: "100%", height: "auto", borderRadius: 5 }}
              />
            </Box>
            <Stack
              spacing={1}
              sx={{ flex: 1, textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" color="#164080" fontWeight={700}>
                Annual Baisakhi Celebration
              </Typography>
              <Typography color="#164080" variant="body2">
                Celebrate the Sikh New Year and the Khalsa Day with us! Enjoy
                prayers, cultural performances, and a festive Langar.
              </Typography>
              <Typography color="#FF7315" variant="body2">
                April 13, 2025
              </Typography>
            </Stack>
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                borderRadius: 5,
                mt: { xs: 2, sm: 0 },
                textTransform: "capitalize",
                bgcolor: isAttending ? "green" : "#FF7315",
                "&:hover": {
                  bgcolor: isAttending ? "darkgreen" : "#FF7315",
                },
              }}
              size="large">
              {isAttending ? "Attending" : "Attend"}
            </Button>
          </Card>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              p: 2,
              mt: 2,
            }}>
            <Box
              sx={{
                mr: { sm: 2 },
                mb: { xs: 2, sm: 0 },
              }}>
              <img
                src={image15}
                alt="Annual Baisakhi Celebration"
                style={{ maxWidth: "100%", height: "auto", borderRadius: 5 }}
              />
            </Box>
            <Stack
              spacing={1}
              sx={{ flex: 1, textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" color="#164080" fontWeight={700}>
                Youth Kirtan Night
              </Typography>
              <Typography color="#164080" variant="body2">
                A night dedicated to our youth, where they can lead the
                congregation in kirtan and learn about Sikh teachings.
              </Typography>
              <Typography color="#FF7315" variant="body2">
                Every Last Friday of the Month
              </Typography>
            </Stack>
            <Button
              variant="contained"
              onClick={handleClick1}
              sx={{
                borderRadius: 5,
                mt: { xs: 2, sm: 0 },
                textTransform: "capitalize",
                bgcolor: isAttending1 ? "green" : "#FF7315",
                "&:hover": {
                  bgcolor: isAttending1 ? "darkgreen" : "#FF7315",
                },
              }}
              size="large">
              {isAttending1 ? "Attending" : "Attend"}
            </Button>
          </Card>
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              p: 2,
              mt: 2,
            }}>
            <Box
              sx={{
                mr: { sm: 2 },
                mb: { xs: 2, sm: 0 },
              }}>
              <img
                src={image12}
                alt="Annual Baisakhi Celebration"
                style={{ maxWidth: "100%", height: "auto", borderRadius: 5 }}
              />
            </Box>
            <Stack
              spacing={1}
              sx={{ flex: 1, textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" color="#164080" fontWeight={700}>
                Annual Baisakhi Celebration
              </Typography>
              <Typography color="#164080" variant="body2">
                Celebrate the Sikh New Year and the Khalsa Day with us! Enjoy
                prayers, cultural performances, and a festive Langar.
              </Typography>
              <Typography color="#FF7315" variant="body2">
                April 13, 2025
              </Typography>
            </Stack>
            <Button
              variant="contained"
              onClick={handleClick2}
              sx={{
                borderRadius: 5,
                mt: { xs: 2, sm: 0 },
                textTransform: "capitalize",
                bgcolor: isAttending2 ? "green" : "#FF7315",
                "&:hover": {
                  bgcolor: isAttending2 ? "darkgreen" : "#FF7315",
                },
              }}
              size="large">
              {isAttending2 ? "Attending" : "Attend"}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default LiveEvent;
