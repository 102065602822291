import React from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HomeImage from "../src/images/Group 60.png";
import { Image, WidthFull } from "@mui/icons-material";
import LensIcon from "@mui/icons-material/Lens";
import Media11 from "../src/images/Media (11).png";
import Media7 from "../src/images/Media (7).png";
import { Navigate, useNavigate } from "react-router-dom";

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCardClick = (index:any) => {
    const routes = ["/contact-us", "/live-events", "/donation"]; 
    navigate(routes[index]); 
  };

  return (
    <>
      <Box
        height={{ xs: "auto", sm: "82vh" }}
        sx={{
          bgcolor: "#164080",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src={HomeImage}
          alt="HomeImage"
          style={{
            width: "100%",
            maxWidth: "90%",
            height: "auto",
            marginTop:80
          }}
        />
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 4,
        }}>
        {/* Main Content Section */}
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          width="100%"
          maxWidth={1200}
          mt={13}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingX: isMobile ? 2 : 0,
          }}>
          {/* Left Content */}
          <Stack spacing={2} alignItems={isMobile ? "center" : "flex-start"}>
            <Typography color="#FF7315" mt={isMobile ? 8 : 20} variant="h6">
              WELCOME
            </Typography>
            <Typography variant="h2" fontWeight={700} color="#164080">
              Who are we?
            </Typography>
          </Stack>

          {/* Right Content */}
          <Stack spacing={2} maxWidth={600}>
            <Typography mt={isMobile ? 4 : 20} color={"#164080"}>
              The Singh Sabha Gurdwara of Austin represents the activity and
              goals of the wider sangat in Austin, Texas. Since 1995, the
              Gurdwara has held regular Gurdwara Diwans in various rented sites.
              The Gurdwara was formally established as a 501(c)(3) corporation
              in 2003.
            </Typography>
            <Typography color={"#164080"}>
              Diwans are held on the second and fourth Sunday of each month. The
              Gurdwara sends an email to registered members and updates the
              Facebook page with the Diwan schedule and timings 2-3 days before
              the Diwan.
            </Typography>
          </Stack>
        </Stack>

        {/* Cards Section */}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{
            mt: 4,
            paddingX: isMobile ? 2 : 0,
          }}>
          {["Join Us", "Upcoming Events", "Make a Donation"].map(
            (text, index) => (
              <Card
                key={index}
                onClick={() => handleCardClick(index)}
                sx={{
                  padding: 8,
                  flex: 1,
                  textAlign: "center",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  borderRadius: 7,
                }}>
                <Box
                  sx={{
                    width: 70,
                    height: 70,
                    backgroundColor: "#FF7315",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 2,
                  }}>
                  <LensIcon sx={{ color: "#FFF", fontSize: 0 }}/>
                </Box>
                <Typography variant="h6" color="#164080" fontWeight={700}>
                  {text}
                </Typography>
              </Card>
            )
          )}
        </Stack>
        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{
            mt: 4,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography color="#FF7315" mt={isMobile ? 8 : 20} variant="h6">
            WELCOME
          </Typography>
          <Typography variant="h2" fontWeight={700} color="#164080">
            Learn About Sikhs
          </Typography>
          <Typography mt={isMobile ? 4 : 20} color={"#164080"}>
            Sikhism (/ˈsɪkɪzəm/) or Sikhi (Punjabi: Sikkhī, [ˈsɪkːʰiː], from,
            Sikh, ‘disciple’, ‘seeker’, or ‘learner’) is a monotheistic and
            panentheistic religion that originated in the Punjab region of the
            Indian subcontinent around the end of the 15th century CE. Sikhism
            is one of the youngest of the major religions and the world’s
            fifth-largest organized religion, with about 30 million Sikhs as of
            the early-21st century.
          </Typography>
          <Typography color={"#164080"}>
            Sikhism developed from the spiritual teachings of Guru Nanak, the
            first Guru (1469–1539), and of the nine Sikh gurus who succeeded
            him. The tenth guru, Gobind Singh (1666–1708), named the Sikh
            scripture Guru Granth Sahib as his successor, bringing to a close
            the line of human gurus and establishing the scripture as the
            eternal, religious spiritual guide for Sikhs. Guru Nanak taught that
            living an “active, creative, and practical life” of “truthfulness,
            fidelity, self-control and purity” is above metaphysical truth, and
            that the ideal man.
          </Typography>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={17}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{
            mt: 6,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Stack>
            <img
              src={Media11}
              alt="Media11"
              width={isMobile ? "400" : "auto"}
            />
          </Stack>
          <Stack>
            <Typography
              variant="h2"
              fontWeight={700}
              align="center"
              color="#164080">
              Place Of Peace
            </Typography>
            <Typography color={"#164080"}>
              A Gurudwara is a haven of peace, where minds find calm, hearts
              find
            </Typography>
            <Typography color={"#164080"} align="center">
              unity, and souls connect with the divine.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={17}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{
            mt: 10,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Stack>
            <Typography
              variant="h2"
              fontWeight={700}
              align="center"
              color="#164080">
              Connect Devotees
            </Typography>
            <Typography color={"#164080"}>
              A place where devotees unite in faith, finding strength and
              connection
            </Typography>
            <Typography color={"#164080"} align="center">
              through shared devotion and service
            </Typography>
          </Stack>
          <Stack>
            <img src={Media7} alt="Media11" width={isMobile ? "400" : "auto"} />
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default Home;
