import React, { useState } from "react";
import * as Yup from "yup";
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  useMediaQuery,
  useTheme,
  Link,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Group62 from "../src/images/Group 62.svg";
import { FormProvider, useForm } from "react-hook-form";
import phone from "../src/images/call.png";
import email from "../src/images/drafts.png";
import map from "../src/images/distance.png";
import { yupResolver } from "@hookform/resolvers/yup";

type FormValuesProps = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  message: string;
};

const ContactUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object().shape({});

  const defaultValues = {
    firstName: "",
    lastName: "",
    message: "",
    email: "",
    mobile: "",
  };

  const methods = useForm<FormValuesProps | any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    reset,
    getValues,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "35vh",
          width: "100%",
          overflow: "hidden",
        }}>
        <img
          src={Group62}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />

        <Stack
          direction={isMobile ? "column" : "column"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={"100%"}
          gap={8}
          sx={{
            mt: 13,
            paddingX: isMobile ? 2 : 0,
          }}>
          <Typography
            variant="h3"
            fontWeight={700}
            color="#FFFFFF">
            Contact Us
          </Typography>
          <Typography
            mt={isMobile ? 4 : 20}
            color={"#FFFFFF"}>
            Raise your hand to make our world to be a better place
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        bgcolor={"#EBF5FF"}
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          padding: 4,
        }}>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{ paddingX: isMobile ? 2 : 0 }}>
          {[
            {
              text: "Phone",
              image: phone,
              details: ["(512) 222-SSGA", "(512-222-7742)"],
            },
            {
              text: "Email",
              image: email,
              details: [
                "Board of Trustees",
                "bot@ssga.groups.io",
                "Executive Committee",
                "ec@ssga.groups.io",
                "Langar Coordinator",
                "langar@austingurdwara.org",
                "Kids Kirtan/Punjabi Classes",
                "punjabiclass@ssga.groups.io",
              ],
            },
            {
              text: "Address",
              image: map,
              details: [
                "6404 J M Holloway Lane",
                "Austin, Texas 78724",
                "Open Daily from 7 am – 6 pm",
              ],
            },
          ].map((item, index) => (
            <Card
              key={index}
              sx={{
                p: 10,
                flex: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                borderRadius: 7,
              }}>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <img
                  src={item.image}
                  alt={item.text}
                  width={index === 1 ? 70 : 70}
                />
              </Box>
              <Typography

                variant="h6"
                color="#164080"
                fontWeight={700}
                align="center">
                {item.text}
              </Typography>

              {/* Display details in pairs for the middle card */}
              {index === 1
                ? item.details
                    .reduce((pairs: any, detail: any, i: any) => {
                      if (i % 2 === 0) pairs.push([]);
                      pairs[pairs.length - 1].push(detail);
                      return pairs;
                    }, [])
                    .map((pair: any, pairIndex: any) => (
                      <Box
                        key={pairIndex}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}>
                        {pair.map((line: any, lineIndex: any) => (
                          <Typography
                            key={lineIndex}
            
                            color="#164080"
                            align="center">
                            {line}
                          </Typography>
                        ))}
                      </Box>
                    ))
                : item.details.map((detail, detailIndex) => (
                    <Typography
                      key={detailIndex}
    
                      color="#164080"
                      align="center"
                      mt={detailIndex === 0 ? 1 : 0}
                      mb={detailIndex === item.details.length - 1 ? 2 : 0}>
                      {detail}
                    </Typography>
                  ))}
            </Card>
          ))}
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={1200}
          sx={{ mt: 4, paddingX: { xs: 2, sm: 4 } }}>
          <Typography
            color="#FF7315"
            mt={{ xs: 8, sm: 20 }}
            variant="h6">
            CONTACT US
          </Typography>
          <Typography
            variant="h2"
            fontWeight={700}
            color="#164080">
            Connect with Us
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Stack spacing={5} alignItems="center" width="100%">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  width="100%"
                  maxWidth={800}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        sx: { border:"1px solid #5FAFFF", borderRadius: 7 },
                      }}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                    }}
                  />
                </Stack>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  width="100%"
                  maxWidth={800}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                    }}
                  />
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                    }}
                  />
                </Stack>
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    width: "100%",
                    maxWidth: 800,
                    borderColor: "#5FAFFF",
                    borderRadius: 2,
                  }}
                  InputProps={{
                    sx: { border: "1px solid #5FAFFF", borderRadius: 7 },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 2,
                    borderRadius: 5,
                    paddingX: 4,
                    textTransform: "capitalize",
                     bgcolor: "#FF7315"
                  }}>
                  Send Message
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Grid>
    </>
  );
};

export default ContactUs;
